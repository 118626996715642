.section1 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -100px;
}

.section {
  height: 100vh;
  /* Ensure no fixed background styles here */
}

.overlay2 {
  position: relative;
  top: 40%; /* Adjust as needed to vertically center */
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  text-align: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  
  padding: 30px;
  
}




.quotey {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 14pt; /* Slightly smaller font size */
  line-height: 1.4; /* Reduce line height for compactness */
  width: 700px;
  height: 400px; /* Smaller width */
  /* Allow height to adjust dynamically based on content */
  margin: 20px auto;
  word-break: break-word;
  text-align: center;
  position: relative;
  background: rgba(0, 0, 0, 0.5); /* Optional: Semi-transparent background */
  backdrop-filter: blur(10px); /* Apply the blur effect */
  -webkit-backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.7); 
  top: 50px;
   /* Add padding for better spacing inside */
   /* Darker background for better text contrast */
  border-radius: 10px; /* Rounded corners *//* Subtle shadow for depth */
  overflow: hidden;
  border: solid #fc5460;
  display: flex;
  justify-content: center;
  align-items: center;
}





.quotey2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 15pt;
  line-height: 1.6;
  padding: 30px;
  width: 600px;
  height: 500px;
  margin: 20px auto;
  word-break: break-word;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
 
  /* Centering Content */
  display: flex;  /* Use flexbox for centering */
  justify-content: center;  /* Center horizontally */
  align-items: center;  /* Center vertically */
  text-align: center;
}

.quotey3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 15pt;
  line-height: 1.6;
  padding: 30px;
  width: 600px;
  height: 500px;
  margin: 20px auto;
  word-break: break-word;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  
  display: flex;  /* Use flexbox for centering */
  justify-content: center;  /* Center horizontally */
  align-items: center;  /* Center vertically */
  text-align: center;
}

.quoteandclue {
  display: flex;
  position: relative;
  left: 25px;
 
}

.button-container {
  position: relative;
  display: flex;
  flex-direction: column;
  right:790px;
  height: 300px;
  top: 120px;
  justify-content: space-evenly;
}

.thequote {
  color: white; /* Ensure the text is white */
  font-size: clamp(16px, 5vw, 25pt);
  z-index: 2; /* Keep text above the overlay */
  position: relative; /* Position text above any other elements */
  text-shadow: 2px 1px 2px rgba(0, 0, 0, 1); /* Add subtle shadow to enhance visibility */
  width: 500px;
 
  height: auto;
  font-family: "Oswald", sans-serif;
}

.thequote3 {
  color: white; /* Ensure the text is white */
  font-size: clamp(16px, 5vw, 25pt);
  z-index: 2; /* Keep text above the overlay */
  position: relative; /* Position text above any other elements */
  text-shadow: 2px 1px 2px rgba(0, 0, 0, 1); /* Add subtle shadow to enhance visibility */
  width: 500px;
  height: auto;
  font-family: "Oswald", sans-serif;
}

/* Add a darker overlay to the background image */
.quotey:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
   /* Make background dimmer */
  z-index: 1;
}

.quotey3:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
   /* Make background dimmer */
  z-index: 1;
}

/* Ensure the text remains above the overlay */
.quotey p {
  position: relative;
  z-index: 2; /* Make sure the text is above the overlay */
  margin: 0;
}





.quotey.fit-text {
  font-size: calc(50px + (25 - 50) * ((100vw - 300px) / (1600 - 300)));
}

.quotey.fade-in {
  opacity: 1;
  transform: translateY(-20px); /* Slight upward movement */
}

.quote-button2 {
  position: relative;
  top: 40%;
  padding: 10px 20px; /* Increase padding */
  font-size: 24px; /* Increase font size */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  border: none;
  cursor: pointer;
  font-weight: bold;
  opacity: 0; /* Initially hidden */
  transition: opacity 1s ease-in-out, transform 0.5s ease-out; /* Smooth transition for fade-in effect */
  background-color: black; /* Green background */
  color:#fc5460; /* White text */
  font-family: "Oswald", sans-serif;
}

.quote-button2.fade-in {
  opacity: 1;
}

.quote-button2:hover {
  transform: scale(1.1); /* Slightly increase size on hover */
  background-color: white; /* Change background to white on hover */
  color: black; /* Change text to green on hover */
}

.title-container {
  display: block;
}

.whosays {
  color: #fc5460; /* Bright text color */
  font-family: "Oswald", sans-serif;
  border:#fc5460 solid;
  font-weight: bolder;
  font-size: 25pt;
  background: rgba(0, 0, 0, 0.5); /* Optional: Semi-transparent background */
  backdrop-filter: blur(10px); /* Apply the blur effect */
  -webkit-backdrop-filter: blur(10px);
  text-shadow: 
    -1px -1px 0 #000,  /* Top-left shadow */
    1px -1px 0 #000,   /* Top-right shadow */
    -1px 1px 0 #000,   /* Bottom-left shadow */
    1px 1px 0 #000;    /* Bottom-right shadow */
  width: 600px;
  position: absolute;
  top: 0%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust centering */
  border-radius: 15px; /* Rounded corners */
  margin-bottom: 20px; /* Spacing below */
  padding: 10px; /* Adjust padding for spacing */
  line-height: 1.2; /* Adjust line height */
  text-transform: uppercase; /* Capitalize text */
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 20px; 
}

.tv {
  width: 75px;
  height: 75px;
  position: relative;
  
}

.boxing {
  width: 75px;
  height: 75px;
}

.tv1 {
  width: 75px;
  height: 75px;
  position: relative;
  
}



.rounds {
  color: #fc5460;
  font-size: 20pt; /* Increase font size */
  padding: 0;
  letter-spacing: 1px; /* Slightly increase letter spacing */
  font-weight: bold;
  margin-top: 10px;
  text-transform: uppercase; /* Capitalize text */
  
}

.search-bar-container {
  display: flex;
  justify-content: center;
  align-items: center; /* Add some spacing from other elements */
  left: 50px;
}

.search-input {
  padding: 15px 20px; /* Increase padding for better feel */
  font-size: 16px;
  border-radius: 10px 25px 25px 10px;
  border: 2px solid #fc5460; /* Match theme color */
  width: 400px;
  outline: none;
  position: relative;
  top: 150px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Subtle shadow for depth */
  transition: all 0.3s ease-in-out;
   /* Make background transparent and blurry */
   background: rgba(255, 255, 255, 0.2); /* Light transparent background */
   backdrop-filter: blur(10px); /* Blur effect */
   -webkit-backdrop-filter: blur(10px); /* For Safari support */
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Subtle shadow for depth */
}

.search-input::placeholder {
  color: #fc5460; /* Change the color to match your theme */
  font-size: 16px; /* Adjust the font size if needed */
  font-weight: bold; /* Make it stand out more */
  opacity: 1; /* Ensure full visibility */
  font-family: "Oswald", sans-serif; /* Match your font style */
}

.search-button {
  background-color: transparent; /* Button background color */
  border: none;

  font-size: 16px;
  font-family: 'Oswald', sans-serif;
  color: black; /* Text color */
  border-radius: 25px; /* Fully rounded button */
  cursor: pointer;

  transition: all 0.3s ease;
  top:152px;
  position: relative;
  left: -60px;
}

.search-button:active {
  transform: scale(0.95); /* Press-down effect */
}

.search-button:hover {
  transform: scale(1.1);
  color: black; /* Change text color */
  /* Enhanced shadow on hover */
}

.search-icon {
  width: 50px; /* Set the desired width for the search icon */
  height: 50px; /* Set the desired height for the search icon */
}

.search-input:focus {
  border-color: #fc5460; /* Highlight border on focus */
  
}
.search-input.incorrect-guess {
  border-color: orangered;
  background-color: #ffdada;
}

.suggestion-item.incorrect-guess {
  color: orangered;
}

.guess-tracker {
  color: white;
  font-family: "Oswald", sans-serif;
  position: relative;
  top: 0px;
  font-weight: bold;
  font-size: 20px; /* Increase font size */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add shadow for visibility */
}

.incorrect-guesses {
  margin-top: 20px;
  height: auto; /* Adjust height */
  font-size: 18px;
  position: relative;
  top: 150px;
  display: flex;
  justify-content: center; /* Start in the center */
  flex-wrap: nowrap; /* Prevent items from wrapping */
  gap: 10px; /* Adjust the gap between guesses */
}

.guess-number {
  color: #fc5460 ;
}

.incorrect-guess-text {
  color: #ff2c2c; /* Red color for incorrect guesses */
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  font-size: 20px;
  margin: 5px 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add shadow for visibility */
}




.disabled-button {
  background-color: #ccc; /* Grey background */
  border-color: #999; /* Grey border */
   /* Disabled cursor */
  filter: opacity(0.5); /* Lower opacity */
   /* Disable clicks */
}

.enabled-button {
  background-color: white; /* Active yellow background */
  cursor: pointer;
  pointer-events: auto; /* Enable clicks */
  filter: opacity(1);
  border: 2px solid white;
}

.enabled-button:hover {
  transform: scale(1.1); /* Slightly increase size on hover */
  background-color: white;/* Change background color on hover */
  color: black; /* Change text color on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
  filter: opacity(1);
}

.disabled-button:hover {
  transform: scale(1); /* Slightly increase size on hover */
  background-color: grey;/* Change background color on hover */
  color: black; /* Change text color on hover */
   /* Enhanced shadow on hover */
  filter: opacity(1);
}

.hint-container {
  
  position: absolute;
  right: 750px;
  top: 130px;
}


.hint-bubble1 {
  position: absolute;
  background-color: transparent; /* Bright yellow background */
  color: #fc5460;
  padding: 15px 20px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: bold;
  font-family: "Oswald", sans-serif;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  white-space: nowrap;
  z-index: 10;
  transition: all 0.3s ease;
  backdrop-filter: blur(10px); /* Apply blur to the background */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  border: 1px solid #fc5460; /* Subtle border for visibility */
}

.hint-bubble3 {
  position: absolute;
  background-color: transparent; /* Bright yellow background */
  color: #fc5460;
  padding: 15px 20px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: bold;
  font-family: "Oswald", sans-serif;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  white-space: nowrap;
  z-index: 10;
  transition: all 0.3s ease;
  backdrop-filter: blur(10px); /* Apply blur to the background */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  border: 1px solid #fc5460; /* Subtle border for visibility */
}

.hint-bubble5 {
  position: absolute;
  background-color: transparent; /* Bright yellow background */
  color: #fc5460;
  padding: 15px 20px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: bold;
  font-family: "Oswald", sans-serif;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  white-space: nowrap;
  z-index: 10;
  transition: all 0.3s ease;
  backdrop-filter: blur(10px); /* Apply blur to the background */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  border: 1px solid #fc5460; /* Subtle border for visibility */
}

.hint-bubble6 {
  position: absolute;
  background-color: #fc5460; /* Bright yellow background */
  color: black;
  padding: 15px 20px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: bold;
  font-family: "Oswald", sans-serif;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  white-space: nowrap;
  z-index: 10;
  transition: all 0.3s ease;
}




/* Add hover effect to give the hint more interaction */
.hint-bubble:hover {
  background-color: white; /* Slightly lighter yellow on hover */
  transform: translateY(-5px); /* Subtle lift on hover */
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

/* Adjust the arrow under the bubble */


.hint-bubble:hover:after {
  border-color: #fc5460 transparent transparent transparent; /* Change arrow color on hover */
}

/* Update the flash animation */
@keyframes flash {
  0%, 100% {
    background-color: #fc5460; /* Original button color */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  50% {
    background-color: #FFEB3B; /* Softer flash color */
    border-color: #FFEB3B transparent transparent transparent;
    box-shadow: 0 0 15px rgba(255, 235, 59, 0.5); /* Subtle shadow */
    transform: scale(1.1);
  }
}

.flash-hint {
  animation: flash 4s infinite; /* Slower animation duration */
}

.give-up-button {
  background-color: #fc5460;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Adds shadow effect */
  position: relative; /* Needed for tooltip positioning */
  margin-top: 50px;
}

.give-up-button:hover {
  background-color: #fc5460;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5); 
}

/* Tooltip styles */
.give-up-button:hover::after {
  content: 'Give up?'; /* Tooltip text */
  position: absolute;
  top: -40px; /* Position above the button */
  left: 50%;
  transform: translateX(-50%);
  background-color: #fc5460; /* Tooltip background color */
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  z-index: 1000;
}

/* Optional tooltip arrow */
.give-up-button:hover::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ff4b4b transparent; /* Arrow pointing down */
}




.suggestions-list2 {
  position: absolute;
  top: 210px; /* Adjust placement as needed */
  left: 837px; /* Adjust for your layout */
  width: 440px;
  background-color: transparent;
  border: 1px solid #fc5460;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 200px; /* Limits height */
  overflow-y: auto; /* Enables vertical scrolling */
  z-index: 1000;
  list-style: none;
  margin: 0;
  padding: 0;
  
  background: rgba(0, 0, 0, 0.5); /* Optional: Semi-transparent background */
  backdrop-filter: blur(10px); /* Apply the blur effect */
  -webkit-backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.7); 
}


.suggestions-list2::-webkit-scrollbar {
  width: 8px;
}

.suggestions-list2::-webkit-scrollbar-thumb {
  background-color: grey; /* Matches your theme */
}

.suggestions-list2::-webkit-scrollbar-track {
  background-color: #e6e6e6; /* Subtle contrast for scrollbar track */
}


.suggestion-item {
  display: flex;
  align-items: center;
  padding: 15px; /* More padding for better spacing */
  cursor: pointer;
  font-size: 18px; /* Increased font size for readability */
  font-weight: bold;
  color: #fc5460;
  text-transform: capitalize;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  border-bottom: 1px solid rgba(0, 0, 0, 1); /* Subtle divider for items */
}

.suggestion-item.highlighted {
  background-color: #e6e6e6; /* Lighter highlight for selection */
}

.suggestion-item:hover {
  background-color: white; /* Highlight background on hover */
  color: black; /* Maintain text color */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow effect */
}

.suggestion-image {
  width: 70px; /* Increased width */
  height: 70px; /* Increased height */
  /* Circular image */
  margin-right: 15px; /* More spacing between image and text */
  object-fit: cover; /* Ensure the image covers the frame without distortion */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add subtle shadow for better visibility */
}

.search-bar-container {
  position: relative; /* Make the search container relative to handle the absolute dropdown */
}





.hint-button, .audio-button {
  position: relative;
  margin: 0 10px;
  font-weight: bold;
  border-radius: 50%;
  margin-bottom: 50px;
  background-color: transparent;
  border: #fc5460 solid;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  width: 50px; /* Adjusted size */
  height: 50px; /* Adjusted size */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  display: flex;
  justify-content: center;
  align-items: center;
}

.hint-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.hint-description {
  margin-top: -45px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  text-align: center;
}
.hint-description3 {
  
  font-size: 12px;
  font-weight: bold;
  color: white;
  text-align: center;
}


.icons {
  max-width: 25px; /* Adjusted size */
  height: 25px; /* Adjusted size */
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5)); /* Add drop shadow for better visibility */
}




.hint-button:hover:after, .audio-button:hover:after {
  content: attr(data-tooltip); /* Use the data-tooltip attribute for the tooltip content */
  position: absolute;
  top: -40px;/* Adjust for tooltip position */
  left: 50%;
  transform: translateX(-50%);
  background-color:#fc5460;
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.hint-button:hover:before, .audio-button:hover:before {
  content: '';
  position: absolute;
  top: -14px;/* Adjust for tooltip arrow */
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.hint-button:active {
  transform: scale(0.98); /* Press-down effect */
}





@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #fff;
  border-radius: 8px;
  text-align: center;
  padding-bottom: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  width: 90%;
}

.modal h2 {
  margin-top: 0;
}

.modal p {
  margin: 15px 0;
  color: black;
}

.modal button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #fc5460;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.modal button:hover {
  background-color: #fc5460;
}



@media (min-width: 0px) and (max-width: 461px) {
  /* Adjust the quote container */
  .section1,
  .section {
    width: 100%; /* Ensure sections fit within the viewport width */
    overflow-x: hidden; /* Prevent horizontal scrolling within sections */
  }


    .whosays {
      color: #fc5460; /* Bright text color */
      font-family: "Oswald", sans-serif;
      font-weight: bolder;
      font-size: 25pt; /* Adjust for smaller screens */
      background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
      backdrop-filter: blur(10px); /* Blur effect */
      -webkit-backdrop-filter: blur(10px); /* For Safari support */
      text-shadow: 
        -1px -1px 0 #000,  /* Top-left shadow */
        1px -1px 0 #000,   /* Top-right shadow */
        -1px 1px 0 #000,   /* Bottom-left shadow */
        1px 1px 0 #000;    /* Bottom-right shadow */
      width: 90%; /* Adjust width to fit smaller screens */
      position: relative; /* Change to relative for easier positioning */
      top: 100px;
      left: 212px;
      /* Center horizontally */
       /* Adjust padding */
      border-radius: 15px; /* Rounded corners */
      line-height: 1.2; /* Adjust line height */
      text-transform: uppercase; /* Capitalize text */
      text-align: center; /* Ensure text alignment */
    }


    .overlay2 {
      position: relative;
      transform: translate(-50%, -50%);
      color: black;
      text-align: center;
      width: 100%;
      justify-content: center;
      align-items: center;
      
      padding: 30px;
      
    }
  
  


    .quotey {
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      font-size: 18pt;
      line-height: 1.4;
      width: 90%; /* Use a percentage to make it responsive */
      max-width: 700px; /* Add a max-width for larger screens */
      height: auto; /* Allow the height to adjust dynamically */
      margin: 20px auto; /* Center horizontally and add spacing from other elements */
      word-break: break-word;
      text-align: center;
      position: relative;
      background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
      backdrop-filter: blur(10px); /* Apply blur effect */
      -webkit-backdrop-filter: blur(10px); /* Safari support */
      padding: 20px; /* Add padding for better spacing */
      border-radius: 10px; /* Rounded corners */
      border: solid #fc5460; /* Match your theme color */
      display: flex;
      justify-content: center;
      align-items: center;
      top: 20px; 
      left: 10px;
    }
    

  .thequote {
    font-size: 20px; /* Scale the font size for small screens */
    line-height: 1.4; /* Adjust line height for readability */
    width: 100%;
    max-width: 100%;
  }

  /* Adjust the header section */
  .guess-tracker {
    color: white;
    font-family: "Oswald", sans-serif;
    position: relative;
    top: -150px;
    font-weight: bold;
    font-size: 20px; /* Increase font size */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add shadow for visibility */
  } 

  /* Search bar container adjustments */
  .search-bar-container {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center-align elements */
    gap: 10px; /* Add spacing between the input and button */
    margin-top: 20px; /* Add spacing from the previous element */
    width: 300px;
    position: relative;
    top: -100px;
  }

  .search-input {
    width: 90%; /* Scale input to fit screen */
    padding: 10px; /* Adjust padding */
    font-size: 14px; /* Reduce font size */
    position: relative;
    left: 10px;
  }

  .search-button {
    width: auto; /* Adjust width to fit content */
    padding: 8px; /* Adjust padding */
    font-size: 14px; /* Reduce font size */
    position: relative;
    top: 87px;
    left: 140px;
  }

  .suggestions-list2 {
    position: absolute;
    top: calc(100% + 75px); /* Position just below the search input */
    left: 14px; /* Align with the input */
    width: 96%; /* Match the width of the search input */
    max-width: 300px; /* Ensure it stays within screen boundaries */
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    backdrop-filter: blur(10px); /* Add blur effect */
    -webkit-backdrop-filter: blur(10px); /* For Safari support */
    border: 1px solid #fc5460;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 200px; /* Limit height */
    overflow-y: auto; /* Enable scrolling if items exceed max height */
    z-index: 1000;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .suggestion-item {
    padding: 10px; /* Adjust padding for better spacing */
    cursor: pointer;
    font-size: 16px; /* Adjust font size for readability */
    font-weight: bold;
    color: #fc5460;
    text-transform: capitalize;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); /* Subtle divider */
  }
  
  .suggestion-item:hover {
    background-color: #fc5460; /* Highlight on hover */
    color: white; /* Change text color */
  }
  
  .suggestion-item.highlighted {
    background-color: #fc5460; /* Highlight for keyboard navigation */
    color: white;
  }
  

  /* Adjust the incorrect guesses section */
  .incorrect-guesses {
    font-size: 12px; /* Reduce font size for mobile */
    text-align: center; /* Align text in the center */
    position: relative;
    top:-50px;
  }

  /* Footer adjustments */
  .footer {
    font-size: 12px; /* Reduce font size */
    text-align: center; /* Center-align the footer */
    padding: 10px; /* Adjust padding */
  }
}



@media (min-width: 1160px) and (max-width: 1180px) {
  .section1,
  .section {
    width: 100%; /* Ensure sections fit within the viewport width */
    overflow-x: hidden; /* Prevent horizontal scrolling within sections */
  }


    .whosays {
      color: #fc5460; /* Bright text color */
      font-family: "Oswald", sans-serif;
      font-weight: bolder;
      font-size: 25pt; /* Adjust for smaller screens */
      background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
      backdrop-filter: blur(10px); /* Blur effect */
      -webkit-backdrop-filter: blur(10px); /* For Safari support */
      text-shadow: 
        -1px -1px 0 #000,  /* Top-left shadow */
        1px -1px 0 #000,   /* Top-right shadow */
        -1px 1px 0 #000,   /* Bottom-left shadow */
        1px 1px 0 #000;    /* Bottom-right shadow */
      width: 90%; /* Adjust width to fit smaller screens */
      position: relative; /* Change to relative for easier positioning */
      top: 100px;
      left: 212px;
      /* Center horizontally */
       /* Adjust padding */
      border-radius: 15px; /* Rounded corners */
      line-height: 1.2; /* Adjust line height */
      text-transform: uppercase; /* Capitalize text */
      text-align: center; /* Ensure text alignment */
    }


    .overlay2 {
      position: relative;
      transform: translate(-50%, -50%);
      color: black;
      text-align: center;
      width: 100%;
      justify-content: center;
      align-items: center;
      
      padding: 30px;
      
    }
  
  


    .quotey {
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      font-size: 18pt;
      line-height: 1.4;
      width: 90%; /* Use a percentage to make it responsive */
      max-width: 700px; /* Add a max-width for larger screens */
      height: auto; /* Allow the height to adjust dynamically */
      margin: 20px auto; /* Center horizontally and add spacing from other elements */
      word-break: break-word;
      text-align: center;
      position: relative;
      background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
      backdrop-filter: blur(10px); /* Apply blur effect */
      -webkit-backdrop-filter: blur(10px); /* Safari support */
      padding: 20px; /* Add padding for better spacing */
      border-radius: 10px; /* Rounded corners */
      border: solid #fc5460; /* Match your theme color */
      display: flex;
      justify-content: center;
      align-items: center;
      top: 20px; 
      left: 10px;
    }
    

  .thequote {
    font-size: 20px; /* Scale the font size for small screens */
    line-height: 1.4; /* Adjust line height for readability */
    width: 100%;
    max-width: 100%;
  }

  /* Adjust the header section */
  .guess-tracker {
    color: white;
    font-family: "Oswald", sans-serif;
    position: relative;
    top: -150px;
    font-weight: bold;
    font-size: 20px; /* Increase font size */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add shadow for visibility */
  } 

  /* Search bar container adjustments */
  .search-bar-container {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center-align elements */
    gap: 10px; /* Add spacing between the input and button */
    margin-top: 20px; /* Add spacing from the previous element */
    width: 300px;
    position: relative;
    top: -100px;
  }

  .search-input {
    width: 90%; /* Scale input to fit screen */
    padding: 10px; /* Adjust padding */
    font-size: 14px; /* Reduce font size */
    position: relative;
    left: 10px;
  }

  .search-button {
    width: auto; /* Adjust width to fit content */
    padding: 8px; /* Adjust padding */
    font-size: 14px; /* Reduce font size */
    position: relative;
    top: 87px;
    left: 140px;
  }

  .suggestions-list2 {
    position: absolute;
    top: calc(100% + 75px); /* Position just below the search input */
    left: 14px; /* Align with the input */
    width: 96%; /* Match the width of the search input */
    max-width: 300px; /* Ensure it stays within screen boundaries */
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    backdrop-filter: blur(10px); /* Add blur effect */
    -webkit-backdrop-filter: blur(10px); /* For Safari support */
    border: 1px solid #fc5460;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 200px; /* Limit height */
    overflow-y: auto; /* Enable scrolling if items exceed max height */
    z-index: 1000;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .suggestion-item {
    padding: 10px; /* Adjust padding for better spacing */
    cursor: pointer;
    font-size: 16px; /* Adjust font size for readability */
    font-weight: bold;
    color: #fc5460;
    text-transform: capitalize;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); /* Subtle divider */
  }
  
  .suggestion-item:hover {
    background-color: #fc5460; /* Highlight on hover */
    color: white; /* Change text color */
  }
  
  .suggestion-item.highlighted {
    background-color: #fc5460; /* Highlight for keyboard navigation */
    color: white;
  }
  

  /* Adjust the incorrect guesses section */
  .incorrect-guesses {
    font-size: 12px; /* Reduce font size for mobile */
    text-align: center; /* Align text in the center */
    position: relative;
    top:-50px;
  }

  /* Footer adjustments */
  .footer {
    font-size: 12px; /* Reduce font size */
    text-align: center; /* Center-align the footer */
    padding: 10px; /* Adjust padding */
  }
}




@media (min-width: 1200px) and (max-width: 1920px) {

  .overlay2 {
    position: relative;
    top: 420px;
    transform: translate(-50%, -50%);
    color: black;
    text-align: center;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .button-container {
    position: relative;
    left: -650px;
    top: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px; /* Space between buttons */
  }

  .hint-container {
  
    position: absolute;
    right: 620px;
    top: 120px;
  }

  .suggestions-list2 {
    position: absolute;
    top: calc(100% + 150px); /* Position just below the search input */
    left: 700px; /* Align with the input */
    width: 96%; /* Match the width of the search input */
    max-width: 440px; /* Ensure it stays within screen boundaries */
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    backdrop-filter: blur(10px); /* Add blur effect */
    -webkit-backdrop-filter: blur(10px); /* For Safari support */
    border: 1px solid #fc5460;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 200px; /* Limit height */
    overflow-y: auto; /* Enable scrolling if items exceed max height */
    z-index: 1000;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .hint-bubble1 {
    position: absolute;
    background-color: transparent; /* Bright yellow background */
    color: #fc5460;
    padding: 15px 20px;
    border-radius: 15px;
    font-size: 14px;
    font-weight: bold;
    font-family: "Oswald", sans-serif;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    white-space: nowrap;

    z-index: 10;
    transition: all 0.3s ease;
    backdrop-filter: blur(10px); /* Apply blur to the background */
    -webkit-backdrop-filter: blur(10px); /* Safari support */
    border: 1px solid #fc5460; /* Subtle border for visibility */
  }

  .hint-bubble3 {
    position: absolute;
    background-color: transparent; /* Bright yellow background */
    color: #fc5460;
    padding: 15px 20px;
    border-radius: 15px;
    font-size: 14px;
    font-weight: bold;
    font-family: "Oswald", sans-serif;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    white-space: nowrap;
  
    z-index: 10;
    transition: all 0.3s ease;
    backdrop-filter: blur(10px); /* Apply blur to the background */
    -webkit-backdrop-filter: blur(10px); /* Safari support */
    border: 1px solid #fc5460; /* Subtle border for visibility */
  }

  .hint-bubble5 {
    position: absolute;
    background-color: transparent; /* Bright yellow background */
    color: #fc5460;
    padding: 15px 20px;
    border-radius: 15px;
    font-size: 14px;
    font-weight: bold;
    font-family: "Oswald", sans-serif;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    white-space: nowrap;

    z-index: 10;
    transition: all 0.3s ease;
    backdrop-filter: blur(10px); /* Apply blur to the background */
    -webkit-backdrop-filter: blur(10px); /* Safari support */
    border: 1px solid #fc5460; /* Subtle border for visibility */
  }
}