.overlay-3 {
    position: relative;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
   justify-content: center;
   align-items: flex-start;
    width: 100%; 
    padding: 20px;
    
    color: white;
}

.section3 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-size: cover;
    position: relative;
   

}


  

.middle-video3 {
    max-width: 450px; /* Increase the width to make the video bigger */
    height: auto;
    /* Add space between video and text */
    border: solid 4px #fc5460;
    box-shadow: 0px 0px 10px #fc5460, 0px 0px 20px #fc5460, 0px 0px 30px #fc5460;
    opacity: 1; /* Make it visible */
    transition: transform 0.3s ease, box-shadow 0.3s ease, opacity 1s ease-in-out;
    z-index: 1000;
}



.text-content3 {
    max-width: 800px; 
    height: 500px;/* Adjust width as needed */
    padding: 20px;
    margin-left: 100px;
     /* Remove top margin */
    text-align: center;
   /* Align with the video on the left */
background: transparent;
   background: rgba(0, 0, 0, 0.5); /* Optional: Semi-transparent background */
  backdrop-filter: blur(10px); /* Apply the blur effect */
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10%;
  border: solid 4px #fc5460;
    box-shadow: 0px 0px 10px #fc5460, 0px 0px 20px #fc5460, 0px 0px 30px #fc5460;
    
}

.congratulations-title3 {
    text-align: center; /* Center text */
    padding: 0;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    font-size: 2em; /* Increase font size */
    color: white; /* Yellow color for the title */
    
}



.congratulations-text3 {
    font-size: 3rem; /* Adjust size */
    color: white; /* Yellow color */
    padding: 0%;
}

.video-description3 {
    text-align: left; /* Center text */
    color: white;
    font-size: 20pt;
    line-height: 1.6;
    max-width: 90%; 
    height: auto;/* Ensure text width doesn’t overflow */
    margin: 20px auto; /* Space around the text */
    font-family: 'Arial', sans-serif;
    text-align: center;
}

.influencer-name3 {
    color:  #fc5460; /* Yellow color for the name */
    font-size: 3rem; /* Adjust size */
    display: inline;
}

.video-button3 {
    padding: 10px 20px; /* Increase padding */
border-radius: 10px;
    font-size: 24px; /* Increase font size */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
    border: none;
    cursor: pointer;
    font-weight: bold;
    opacity: 1; /* Initially hidden */
    transition: opacity 1s ease-in-out, transform 0.5s ease-out; /* Smooth transition for fade-in effect */
    position: fixed; /* Changed to fixed positioning */
    bottom: -5px;/* Positioned 20px from the bottom */
    right: 300px; /* Positioned in the middle */
    transform: translateX(-50%); /* Center the button horizontally */
    background-color:  #fc5460; /* Green background */
  color: black; /* Button text color */
    font-family: "Oswald", sans-serif; 
    z-index: 1000;
}

.video-button3:hover {
    transform: scale(1.1);
    background-color: white;
    color: black;
}

.video-button3.fade-in {
    opacity: 1;
    transform: translateY(-20px);
}

.caps {
    text-transform: capitalize;
}

.finish-overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.95);
    background-image: url("../assets/l100.jpg"); 
     border: #f04c4c solid;
    
   /* Ensure proper opacity */
    color:  white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.9); /* Enhanced shadow */
    text-align: center;
    z-index: 10000;
    height: 900px;
    width: 1500px;
    background-size: cover;
    display: flex; /* Use flexbox for centering */
    flex-direction: column; /* Stack elements vertically */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
}

.finish-overlay::before {
    content: '';
    position: fixed; /* Ensures it covers the viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: brightness(0.3);
    background-image: url('../assets/l100.jpg');
   /* Ensure the path is correct */
    background-size: cover; /* Make the image cover the entire viewport */
    background-attachment: fixed; /* Fix the image so it doesn't move when scrolling */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
   /* Adjust the brightness of the image */
    z-index: -1; /* Push the background behind other content */
    
  }


.finish-overlay h2 {
    margin: 0 0 10px;
    font-size: 4rem;
    color: #f04c4c;
    text-shadow: 
    -1px -1px 0 #000,  /* Top-left shadow */
    1px -1px 0 #000,   /* Top-right shadow */
    -1px 1px 0 #000,   /* Bottom-left shadow */
    1px 1px 0 #000; 
}

.finish-overlay p {
    margin: 10px 0; 
    font-size: 2.5rem;
    color: white;
    text-shadow: 
    -1px -1px 0 #000,  /* Top-left shadow */
    1px -1px 0 #000,   /* Top-right shadow */
    -1px 1px 0 #000,   /* Bottom-left shadow */
    1px 1px 0 #000;  
}

.overlay-close {
    position: relative;
    top: 100px;
    padding: 10px 20px;
    background-color:  #fc5460;
    color: black;
    border: none;
    cursor: pointer;
    font-size: 1.3rem;
    border-radius: 5px;
    transition: transform 0.3s ease;
    font-family: "Oswald", sans-serif; 
    font-weight: bolder;
}

.overlay-close:hover {
    transform: scale(1.1);
}

.highlight {
    color: #fc5460; /* Yellow color */
    font-weight: bold;
}


@media (min-width:0px) and (max-width: 360.99px) {
    .middle-video {
        width: 90%;
        max-width: 100%;
        height: auto;
    }

        
    .overlay-v {
        top: 43%;
    }
    .overlay-content {
        width: 80%;
        
        position: relative;
    }
  }

  @media (min-width:361px) and (max-width: 409.99px) {
    .middle-video {
        width: 80%;
        max-width: 100%;
        height: auto;
    }

        
    .overlay-v {
        top: 43%;
    }
    .overlay-content {
        width: 80%;
        
        position: relative;
    }
  }

  

  @media (min-width: 410px) and (max-width: 429.99px) {
    .middle-video {
        width: 70%;
        height: auto;
        max-width: 100%;
    }

    .overlay-content {
        width: 75%;
        position: relative;
    }
  }





  @media (min-width: 430px) and (max-width: 539.99px) {
    .middle-video {
        width: 80%;
        height: auto;
        max-width: 100%;
    }

    .overlay-content {
        width: 80%;
        
        position: relative;
    }
  }

  @media (min-width: 540px) and (max-width: 576.98px) {
    .middle-video {
        width: 60%;
        height: auto;
        max-width: 100%;
    }
  }


/* Ipad Air (820px) */
  @media (min-width: 577px) and (max-width: 821.99px) {
    .middle-video {
        width: 60%;
        height: auto;
        max-width: 100%;
        
    }

    .overlay-v {
        top: 45%;
    }

    .overlay-content {
        width: 80%;
        
        position: relative;
    }
  
  }


  @media (min-width: 822px) and (max-width: 913.99px) {
    .middle-video {
        width: 60%;
        height: auto;
        max-width: 100%;
        
    }

    .overlay-v {
        top: 45%;
    }
    .overlay-content {
        width: 100%;
        
        position: relative;
    }

  }

/* Ipad Pro (1024px) */
  @media (min-width: 914px) and (max-width: 1024px) {

    .middle-video {
        width: 60%;
        height: auto;
        max-width: 100%;
        
    }

    .overlay-v {
        top: 35%;
    }
    .overlay-content {
        width: 100%;
        
        position: relative;
    }

  }