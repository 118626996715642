body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  
}

body {
  background-image: url('./assets/m11.png'); /* Ensure the path is correct */
  background-color: black;
  background-size: 100%; /* Make the image cover the entire viewport */
  background-attachment: fixed; /* Fix the image so it doesn't move when scrolling */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: Arial, sans-serif; /* Adjust the font if needed */
  color: white; /* Default text color, adjust as needed */
}

body::before {
  content: '';
  position: fixed; /* Ensures it covers the viewport */
  top: 0;
  
  width: 100%;
  height: 100%;
  filter: brightness(0.5);
  background-image: url('./assets/m11.png'), url('./assets/blur1.png');
  background-size: 100% 100%, cover; /* Make the image cover the entire viewport */
  background-attachment: fixed; /* Fix the image so it doesn't move when scrolling */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
 /* Adjust the brightness of the image */
  z-index: -1; /* Push the background behind other content */
}




.App {
  text-align: center;
}

/* Style for the menu button */
.menu-button {
  position: fixed;
  top: 10px; /* Adjust as needed */
  left:3%; /* Center horizontally */
  transform: translateX(-50%);
  z-index: 1000;
  background-color: #fc5460;
  border: 2px solid #fc5460;
  color: #fc5460;
  padding: 3px 10px;
  font-size: 20px;
  font-family: "Oswald", sans-serif;
  cursor: pointer;
  transition: transform 0.3s ease;
  border-radius: 15px;
}

.menu-button:hover {
  background-color: #fc5460;
  color: white;
}

.menu-button.open {
  color: black; /* Rotate for visual effect */
  border: 2px solid;
}
.menu-button.open:hover {
  color: white;
  border: solid white;
}



.active-link {
  background-color: #fc5460; /* Highlight effect */
  color: black; /* Change text color */
  padding-left: 20px;
  font-weight: bold;
  border-left: 5px solid #fc5460; /* Optional border for extra highlight */
}


/* Other existing styles remain the same */


.nav-menu {
  position: fixed;
  top: -60px; /* Hidden initially */
  left: 0;
  height: 60px; /* Thinner height */
  width: 100%;
  background-color: transparent; /* Transparent yellow for modern look */
  transition: top 0.3s ease;
  display: flex;
  flex-direction: row; /* Horizontal layout */
  justify-content: space-around; /* Even spacing between items */
  align-items: center; /* Center items vertically */
  z-index: 999;
  padding: 0 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15); /* Subtle shadow for depth */
  border-bottom: 1px solid#fc5460; /* Subtle bottom border */
}

.nav-menu.open {
  top: 0; /* Slide down to become visible */
}

.nav-item {
  padding: 5px 100px; /* Smaller padding for a thinner menu */
  font-size: 20pt; 
  font-family: "Oswald", sans-serif;
  font-weight: 600; /* Bold for emphasis */
  color: #fc5460;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
  border-radius: 5px; /* Rounded edges for hover effect */
}

.nav-item:hover {
  background-color: white; /* Highlight on hover */
  color: #fc5460; /* Change text color */
  transform: scale(1.05); /* Slight scaling effect on hover */
}

.nav-item a {
  display: block; /* Makes the entire area clickable */
  width: 100%; /* Ensure it fills the container */
  height: 100%; /* Ensures it matches the height of the button */
  /* Add padding for better spacing */
  text-align: center; /* Center the text */
  color: inherit; /* Inherit the color from the parent */
  text-decoration: none; /* Remove the underline */
}

.nav-item a:hover {
  text-decoration: underline; /* Optional underline on hover */
}




.logo-container {
  position: fixed;
  top: 50px;
  left: -2%; /* Move to the right side */
  transform: translateX(50%); /* Adjust for the right-aligned position */
  z-index: 1000;
  padding: 10px 0;
}

.motivdle-small {
  font-size: 1.2rem;
  color: #f3b32b;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  margin-top: -20px;

  letter-spacing: -2px;
  font-weight: 600;
}

.cheetah-logo {
  width: 130px;
  height: auto;
  background-color: transparent;
}


/* Other existing styles remain the same */




body, html, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  color: inherit; /* Ensure the link inherits the color of the parent */
}

a:hover {
  text-decoration: none; /* Remove underline on hover */
}

a .title:hover {
  text-decoration: none; /* Ensure title is not underlined on hover */
}

.overlay:hover .title,
.overlay:hover .subtitle {
  color: #181818; /* Change this color to your desired hover color */
}


.title {
  font-size: 12rem;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  position: relative;
  text-align: center;
  color: white;
  
}



.footer {
  padding: 10px;
  color: white;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #14213D; /* This is a fallback if the image fails */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #f3b32b;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: opacity 0.3s;
}

.preloader.hidden {
  opacity: 0;
  visibility: hidden;
}


@media (min-width: 0px) and (max-width: 431px) {
  body {
    background-image: url('./assets/m11.png'); /* Use only the larger image */
    background-size: cover;
     /* Zoom and scale proportionally */
    background-position: center; /* Focus the background in the center */
    background-repeat: no-repeat; /* No repeating */
    overflow-x: hidden;
    font-family: Arial, sans-serif;
    color: white;
    
}


/* Optional for a content layer on top */
body::before {
  content: ''; /* Remove if used */
  background-size: 150%;
  display: none; /* Hides the additional layer */
  background-image: url('./assets/m11.png'); /* Use only the larger image */
    background-size: cover;
     /* Zoom and scale proportionally */
    background-position: center; /* Focus the background in the center */
    background-repeat: no-repeat; /* No repeating */
    overflow-x: hidden;
    font-family: Arial, sans-serif;
    color: white;
    

}
.nav-menu {
  display: none; /* Hide the navigation menu */
}

  
  .menu-button {
      display: none;
  }

  .footer {
      font-size: 0.8rem; /* Scale down footer text */
      text-align: center;
      padding: 10px;
  }
}

@media (min-width: 1000px) and (max-width: 1500px) {
  body {
    background-image: url('./assets/m11.png'); /* Use only the larger image */
    background-size: cover;
     /* Zoom and scale proportionally */
    background-position: center; /* Focus the background in the center */
    background-repeat: no-repeat; /* No repeating */
    overflow-x: hidden;
    font-family: Arial, sans-serif;
    color: white;
    
}


/* Optional for a content layer on top */
body::before {
  content: ''; /* Remove if used */
  background-size: 150%;
  display: none; /* Hides the additional layer */
  background-image: url('./assets/m11.png'); /* Use only the larger image */
    background-size: cover;
     /* Zoom and scale proportionally */
    background-position: center; /* Focus the background in the center */
    background-repeat: no-repeat; /* No repeating */
    overflow-x: hidden;
    font-family: Arial, sans-serif;
    color: white;
    

}
.nav-menu {
  display: none; /* Hide the navigation menu */
}

  
  .menu-button {
      display: none;
  }

  .footer {
      font-size: 0.8rem; /* Scale down footer text */
      text-align: center;
      padding: 10px;
  }
}
